<template>
  <div class="footer">
    <div class="top_bg">
      <div class="footer_t">
        <ul>
          <li
            v-for="(item, index) in navList"
            :key="index"
            @click="changToPage(item.routerPath)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <div class="footer_bg">
      <div class="footer_b">
        <div class="footer_b_l">
          <div style="font-size: 18px">{{ footerData.corporate_name }}</div>
          <div>地址：{{ footerData.address || "" }}</div>
          <div class="contact_time">
            <span>业务咨询热线：{{ footerData.advice_line }}</span>
            <span>联系微信客服：周一至周日09:00-22:00</span>
          </div>
          <div>邮箱：{{ footerData.email || "" }}</div>
          <div>Copyright@gzyundong.com版本所有 粤ICP备19018327号</div>
        </div>
        <div class="footer_b_r">
          <img
            :src="item.src"
            :title="item.title"
            class="hangye"
            v-for="(item, index) in footerData.images"
            :key="index + 'a'"
          />
          <div v-for="(item, index) in footerData.qrcode_list" :key="index">
            <img :src="item.src" class="qrcode" />
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getBottomNav } from "@/api/index.js";
export default {
  data() {
    return {
      navList: [
        { title: "商务合作", routerPath: "/cooperation" },
        { title: "关于运东", routerPath: "/aboutUs" },
      ],
      footerData: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      const res = await getBottomNav();
      this.footerData = res.data;
    },
    changToPage(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style scoped lang="scss">
$width: 1600px;
.footer {
  width: 100vw;
  height: auto;
  .top_bg {
    width: 100%;
    height: 60px;
    background: #ffa71d;
  }
  .footer_bg {
    width: 100%;
    height: auto;
    background: #383838;
  }
  &_t {
    width: $width;
    height: 60px;
    padding: 0 50px;
    box-sizing: border-box;
    margin: 0 auto;
    box-sizing: border-box;
    line-height: 60px;
    font-size: 16px;
    ul {
      display: flex;
      li {
        color: #fff;
        cursor: pointer;
        padding-right: 70px;
        box-sizing: border-box;
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE 10+/Edge */
        user-select: none;
      }
    }
  }
  &_b {
    width: $width;
    height: auto;
    margin: 0 auto;
    padding: 40px 50px 20px 50px;
    box-sizing: border-box;
    color: #999999;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    &_l {
      .contact_time {
        display: flex;
        column-gap: 50px;
      }
      div {
        margin-bottom: 19px;
      }
    }
    &_r {
      display: flex;
      column-gap: 40px;
      .hangye {
        width: 124px;
        height: 47px;
        margin-top: 40px;
      }
      div {
        display: flex;
        flex-direction: column;
        text-align: center;
        img {
          margin-bottom: 12px;
        }
        .qrcode {
          width: 125px;
          height: 125px;
        }
      }
    }
  }
}
</style>
