<template>
  <div class="footer" ref="footer">
    <div class="footer_top">
      <span v-for="(item,index) in navList" :key="index" @click="changToPage(item)">{{ item.title }}</span>
    </div>
    <div class="footer_bottom">
      <div class="footer_img">
        <div class="footer_img_l">
          <div v-for="(item, index) in footerData.qrcode_list" :key="index" class="img_item">
            <img :src="item.src">
            <span>{{ item.title }}</span>
          </div>
        </div>
        <div class="footer_img_r">
          <img :src="item.src" :title="item.title" v-for="(item, index) in footerData.images" :key="index + 'a'">
        </div>
      </div>
      <div class="footer_content">
        <div class="company">{{ footerData.corporate_name }}</div>
        <div>地址：{{ footerData.address || "" }}</div>
        <div>业务咨询热线：{{ footerData.advice_line }}</div>
        <div>联系微信客服：周一至周日09:00-22:00</div>
        <div>邮箱：{{ footerData.email || "" }}</div>
        <div>Copyright@gzyundong.com版本所有 粤ICP备19018327号</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getBottomNav } from "@/api/index.js";
export default {
  data() {
    return {
      navList: [
        { title: "商务合作", routerPath: "/cooperation" },
        { title: "关于运东", routerPath: "/aboutUs" },
      ],
      footerData: {}
    }
  },
  mounted() {
    this.initData();
    this.$store.commit("setDistanceToBottom",this.$refs.footer.offsetHeight);
  },
  methods: {
    async initData() {
      const res = await getBottomNav();
      this.footerData = res.data;
    },
    changToPage(item) {
      this.$router.push(item.routerPath)
      window.localStorage.setItem('routeTitle',item.title)
    }
  },
}
</script>
<style scoped lang="scss">
.footer {
    width: 100vw;
    height: auto;
    &_top {
      height: 90px;
      background-color: #FFA71D;
      font-size: 28px;
      color: #fff;
      display: flex;
      align-items: center;
      column-gap: 60px;
      padding: 0 0 0 30px;
      box-sizing: border-box;
    }
    &_bottom {
      padding: 40px 30px;
      box-sizing: border-box;
      background: #383838;
      color: #999999;
      font-size: 22px;
      .footer_img {
        display: flex;
        column-gap: 30px;
        &_l {
          display: flex;
          column-gap: 30px;
          .img_item {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            align-items: center;
            img {
              width: 125px;
              height: 125px;
            }
          }
        }
        &_r {
          display: flex;
          column-gap: 20px;
          padding-top: 38px;
          img {
            width: 124px;
            height: 47px;
          }
        }
      }
      .footer_content {
        margin-top: 40px;
        font-size: 22px;
        display: flex;
        flex-direction: column;
        row-gap: 22px;
        .company {
          font-size: 28px;
        }
      }
    }
}
</style>
