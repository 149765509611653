import request from "@/utils/request";

//获取首页数据
export function getIndex(params) {
  return request({
    url: "/web/mch/official/index",
    method: "get",
    params: { ...params, mch_no: 375 },
  });
}
//获取底部导航数据
export function getBottomNav(params) {
  return request({
    url: "/web/mch/official/bottom_nav",
    method: "get",
    params: { ...params, mch_no: 375 },
  });
}
