<template>
    <div>
        <div class="content" v-if="showToast">
            {{ message }}
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showToast: true,
            type: "normal",
            message: "消息提示",
            duration: 3000
        }
    },
    methods: {
        
    }
}
</script>
<style scoped lang="scss">
.content {
    padding: 8px 20px;
    box-sizing: border-box;
    background: rgba($color: #000000, $alpha: 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
</style>
