import request from "@/utils/request";

//获取品牌导航
export function getBrandList(params) {
  return request({
    url: "/web/mch/official/brand/list",
    method: "get",
    params: { ...params, mch_no: 375 },
  });
}

//获取品牌导航详情
export function getBrandDetail(params) {
  return request({
    url: `/web/mch/official/brand/${params}`,
    method: "get",
    params: { mch_no: 375 },
  });
}
