<template>
    <div class="container">
        <div class="header" v-if="currentPath == '/'">
            <div class="header_item" v-for="(item,index) in navList" :key="index" @click="toRoutePath(item)">
                <img :src="require(`@/assets/icon/${item.icon}.png`)" />
                <span>{{ item.title }}</span>
            </div>
        </div>
        <div class="un_index" v-else>
            <div class="un_index_t">
                <img src="@/assets/image/fh.png" @click="goBack">
                <div class="title">{{ currentTitle }}</div>
            </div>
            <div class="un_index_f" v-if="currentPath == '/brandDetail'">
                <div class="brand_item" :class="item.active?'active':''" v-for="(item,index) in brandList" :key="index" @click="changeBrand(item)">
                    {{ item.title }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getBrandList } from '@/api/brand'
export default {
    data() {
        return {
            navList: [
                { title: '关于运东',routerPath: '/aboutUs',icon: 'gyyd' },
                { title: '商务合作',routerPath: '/cooperation',icon: 'swhz' },
                { title: '品牌公寓',routerPath: '#',icon: 'ppgy' },
                { title: '我要找房',routerPath: '/lookHouse',icon: 'wyzf' },
            ],
            currentPath: '',
            currentTitle: '',
            brandList: []
        }
    },
    watch: {
        $route(n) {
            window.localStorage.setItem('routePath',n.path)
            this.currentPath = window.localStorage.getItem('routePath')
            this.currentTitle = window.localStorage.getItem('routeTitle')
            
            if(n.path == '/brandDetail') {
                this.brandList.forEach(item => {
                    if(n.query.id == item.brand_id) {
                        item.active = true
                    }else {
                        item.active = false
                    }
                })
            }
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        async initData() {
            const res = await getBrandList()
            if(res.code == 200) {
                this.brandList = res.data.map(item => {
                    return {
                        brand_id: item.brand_id,
                        title: item.title,
                        active: false
                    }
                })
                this.brandList.forEach(item => {
                    if(this.$route.query.id == item.brand_id) {
                        item.active = true
                    }else {
                        item.active = false
                    }
                })
            }
        },
        goBack() {
            if(this.currentPath  == '/newsDetail') {
                this.$router.go(-1)
            }else {
                this.$router.push('/')
            }
        },
        toRoutePath(item) {
            if(item.routerPath != '#') {
                this.$router.push(item.routerPath)
                window.localStorage.setItem('routeTitle',item.title)
            }else {
                this.$router.push(`/brandDetail?id=${this.brandList[0].brand_id}`)
                window.localStorage.setItem('routeTitle','运东品牌')
            }
        },
        changeBrand(val) {
            this.brandList.forEach(item => {
                if(val.brand_id == item.brand_id) {
                    item.active = true
                    this.$router.push(`/brandDetail?id=${item.brand_id}`)
                }else {
                    item.active = false
                }
            })
        }
    }
}
</script>
<style scoped lang="scss">
.container {
    position: sticky;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid #eee;
    .header {
        width: 100vw;
        height: 210px;
        background: #fff;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        font-size: 24px;
        color: #666666;
        &_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 18px;
            img {
                border-radius: 50%;
                width: 84px;
                height: 84px;
            }
        }
    }
    .un_index {
        width: 100vw;
        min-height: 100px;
        background: #fff;
        color: '#333333';
        font-size: 36px;
        font-weight: bold;
        &_t {
            display: flex;
            align-items: center;
            padding: 0 18px;
            box-sizing: border-box;
            height: 100px;
            img {
                width: 44px;
                height: 44px;
            }
            .title {
                margin-left: calc(50% - 44px);
                transform: translateX(-50%);
            }
        }
        &_f {
            height: 100px;
            display: flex;
            align-items: center;
            font-size: 32px;
            color: #999999;
            font-weight: 400;
            overflow-x: scroll;
            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
            }
            .brand_item {
                padding: 0 25px;
                box-sizing: border-box;
                flex-shrink: 0;
            }
            .active {
                color: #333333;
                position: relative;
                transition: all 0.3s ease;
                &::after {
                    content: '';
                    width: 130px;
                    height: 8px;
                    background: #FFA71D;
                    border-radius: 4px;
                    position: absolute;
                    bottom: -28px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}
</style>